
import { defineComponent } from 'vue';
import Markdown from 'vue3-markdown-it';
import MarkdownAbbr from 'markdown-it-abbr';
import MarkdownAnchor from 'markdown-it-anchor';
import MarkdownDeflist from 'markdown-it-deflist';
import MarkdownEmoji from 'markdown-it-emoji';
import MarkdownFootnote from 'markdown-it-footnote';
import MarkdownHighlightJs from 'markdown-it-highlightjs';
import MarkdownIns from 'markdown-it-ins';
import MarkdownMark from 'markdown-it-mark';
import MarkdownSub from 'markdown-it-sub';
import MarkdownSup from 'markdown-it-sup';
import MarkdownTaskLists from 'markdown-it-task-list';
import MarkdownTocDoneRight from 'markdown-it-toc-done-right';

import Panel from '@/components/layout/Panel.vue';
import ViewMixin from '@/mixins/ViewMixin';
import * as companyService from '@/services/social/companyService';
import { Company } from '@/interfaces/social/company';
import LoadingIndicatorBeam from '@/components/loading/LoadingIndicatorBeam.vue';
import EditCompany from '@/components/social/company/EditCompany.vue';
import 'highlight.js';
import 'highlight.js/styles/monokai.css';
import { JwtUser } from '@/interfaces/identity/user';
import Button from '@/components/controls/Button';

interface Data {
    isLoading: boolean;
    company: Company | null;
    showEdit: boolean;
    editAboutUs: string;
}

export default defineComponent({
    name: 'Company',
    components: {
        Button,
        EditCompany,
        Markdown,
        Panel,
        LoadingIndicatorBeam,
    },
    mixins: [ViewMixin],
    props: {
        companyId: {
            type: String,
            default: null,
        },
    },
    data: (): Data => ({
        isLoading: false,
        company: null,
        showEdit: false,
        editAboutUs: '',
    }),
    watch: {
        companyId(): void {
            this.company = null;
            this.refreshData();
        },
    },
    computed: {
        hasEditRights(): boolean | null {
            return this.user && this.company && this.user.id === this.company.creatorId;
        },
        user(): JwtUser | null {
            return this.$store.getters['authentication/user'];
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        markdownPlugins(): Record<string, any>[] {
            return [
                { plugin: MarkdownAbbr },
                { plugin: MarkdownAnchor },
                { plugin: MarkdownDeflist },
                { plugin: MarkdownEmoji },
                { plugin: MarkdownFootnote },
                { plugin: MarkdownHighlightJs },
                { plugin: MarkdownMark },
                { plugin: MarkdownIns },
                { plugin: MarkdownSub },
                { plugin: MarkdownSup },
                { plugin: MarkdownTaskLists },
                { plugin: MarkdownTocDoneRight },
            ];
        },
        logoStyle(): Record<string, string> {
            return {
                backgroundImage: this.company && this.company.logoUri ? `url('${this.company.logoUri}')` : '',
            };
        },
    },
    methods: {
        handleUpdateAboutUs(aboutUs: string): void {
            this.editAboutUs = aboutUs;
        },
        handleCreated(company: Company): void {
            this.$router.push({
                name: 'social_company',
                params: {
                    companyId: company.id,
                },
            });
        },
        handlePatched(): void {
            this.showEdit = false;
            this.refreshData();
        },
        handleCancelled(): void {
            this.showEdit = false;
            if (!this.companyId) {
                this.$router.push({ name: 'social_companies' });
            }
        },
        async refreshData(): Promise<void> {
            await this.loadCompany();
        },
        async loadCompany(): Promise<void> {
            if (!this.companyId) {
                return;
            }
            this.isLoading = true;
            try {
                const response = await companyService.getOneOrDefault(this.companyId);
                this.company = response.data;
                this.setPageTitle([this.company.name, this.$t('company'), this.$t('social')]);
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
    },
    created(): void {
        this.setPageTitle([this.$t('company'), this.$t('social')]);
        this.refreshData();
    },
});
